import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import PartnerService from "../../../components/partners/services/PartnerService";
import PartnerModel from "../../../components/partners/models/PartnerModel";
import MasterScreenMain from "../../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faAnchorCircleCheck,
    faCheckDouble, faDownload, faFaceMeh,
    faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";
import PartnerSubMenu from "../../../components/partners/ui/PartnerSubMenu";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import {AppBar, Button, Divider, IconButton, ListItem, Toolbar, Tooltip, Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import TransitionModel from "../../../components/common/ui/TransitionModel.tsx";
import EmptyBox from "../../../components/common/ui/EmptyBox";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";

const PartnerStatements = (props) => {
    const { partnerId } = useParams();
    
    let _;
    let [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel({}));
    let [statementHistory, setStatementHistory] = useState(null);

    const [statementOpen, setStatementOpen] = useState(false);

    const getInvoiceHistoryAsync = async (e) => {
        await PartnerService.instance.getStatementHistoryAsync(partnerId).then((history) => {
            setStatementHistory(history);
        });
    };
    
    useEffect(() => {
        if (!partner.hasDetails) PartnerService.instance.getPartnerAsync(partnerId)
            .then((partner) => {
                console.log('Got Partner: ' + partner.customer.toJson().first_name);
                setPartner(partner);
            })
            .catch((error) => console.log('Ex: ' + error));
        
        _ = getInvoiceHistoryAsync();
    }, []);

    const keyFormat = 'yyyy-MM-01';
    let todayKey = (new Date()).addMonths(-1).formatDate(keyFormat);

    const handleNewStatementAsync = async (e) => {
        await PartnerService.instance.createStatementAsync(partnerId, todayKey).then((s) => {
            console.log("Statement Created: " + s.id);
            getInvoiceHistoryAsync(e);
        });
    };
    
    const openStatement = (e) => {
        setStatementOpen(true);
    };

    const closeStatement = (e) => {
        setStatementOpen(false);
    };
    
    const generateStatement = (statementId, e) => {
        //window.location.href = 'https://sandbox.cardsync.io/cardsync-statement/' + statementId;
    };
    
    const getStatementHtml = (statementId, e) => {
        window.location.href = "/api/statement/" + statementId + "/render?download=true&session-id=" + AuthenticationService.instance.sessionId;
    };
    
    const createHistoryLineItem = (statement) => {
        let cn = (statement.status === 1) ? 'sent' : 'needed';
        let status = (statement.status === 1) ?
            (<><FontAwesomeIcon icon={faCheckDouble} /> Sent</>) :
            (<a onClick={generateStatement.bind(this, statement.id)}>Create <FontAwesomeIcon icon={faTriangleExclamation} /></a>);
        
        return (
            <tr key={statement.created.toString()}>
                <td>
                    <Link to={'/partners/' + partnerId + '/statements/' + statement.id}>
                        <DateTime useUtc={true} value={statement.serviceDate} defaultValue="N/A" />
                    </Link>
                </td>
                <td>{statement.updateCount.formatNumber()}</td>
                <td>{statement.newEnrollments.formatNumber()}</td>
                <td>{statement.activeMerchants.formatNumber()}</td>
                <td>
                    <a>{statement.total.formatCurrency()}</a>
                </td>
                <td className={cn}>
                    {status}
                    <a onClick={getStatementHtml.bind(this, statement.id)}><FontAwesomeIcon icon={faDownload} /></a>
                </td>
            </tr>
        );        
    };
    
    let generateButton = statementHistory !== null ? (<RefreshButton onClick={handleNewStatementAsync} caption={"Generate"} />) : null;
    
    let historyElements = statementHistory?.map((statement) => {
        let serviceKey = statement.serviceDate.formatDate(keyFormat);
        
        if (serviceKey === todayKey) generateButton = null;
        else console.warn("Service Key: " + serviceKey + " Today Key: " + todayKey);
        
        return createHistoryLineItem(statement);
    }) ?? null;
    
    let emptyBox = historyElements?.length === 0 ?
        (<EmptyBox className={"middle"}><span>No statements have been created for { partner?.name }</span><FontAwesomeIcon icon={faFaceMeh} /></EmptyBox>) : 
        (<></>);
    
    return (
        <MasterScreenMain selection="partners">
            <div className="pad full-screen-dialog">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body body">
                    <PartnerSubMenu partner={partner} selection="statements" />

                    <div className="pad">
                        <h2>
                            Statements

                            {generateButton}
                            <RefreshButton onClick={getInvoiceHistoryAsync} />
                        </h2>
                        <p>
                            Partners can be billed in a number of ways. Below are the historical transactions for this {partner?.name}
                        </p>
                        <div id="statement-history">
                            
                            <table className="table-x-large" width="100%">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Updates</th>
                                        <th>New Enrollments</th>
                                        <th>Active Merchants</th>
                                        <th>Total</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historyElements}
                                </tbody>
                            </table>

                            {emptyBox}
                        </div>
                    </div>
                </div>
            </div>

            <div style={{backgroundColor: 'red !important'}}>
                <Dialog
                    fullScreen
                    open={statementOpen}
                    onClose={closeStatement}
                    TransitionComponent={TransitionModel}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <div className="dialog-fullscreen-header">
                            Toast...
                            <Button autoFocus color="inherit" onClick={closeStatement}>
                                save
                            </Button>
                        </div>
                    </AppBar>
                    
                </Dialog>
            </div>            
        </MasterScreenMain>
    );    
    
};

export default PartnerStatements;

