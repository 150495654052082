import React from 'react';
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckToSlot, faSquarePollVertical } from "@fortawesome/free-solid-svg-icons";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import VisaCard from "../../../payments/ui/CreditCards/VisaCard";
import MasterCard from "../../../payments/ui/CreditCards/MasterCard";
import DiscoverCard from "../../../payments/ui/CreditCards/DiscoverCard";
import {Link} from "react-router-dom";
import AmericanExpressCard from "../../../payments/ui/CreditCards/AmericanExpressCard";

const BatchResultsActivity = (props) => {
    const { batch, partner, onClick } = props;

    let merchantPath = '/partners/' + partner.id + '/merchants/' + batch.subMerchantId;
    let path = merchantPath + '/batch/' + batch.batchId;
    
    let updateCount = batch.updateCount || 0;
    let percent = updateCount > 0 ? ((updateCount / batch.submittedCount) * 100).toFixed(1) : 0.0;
    let resultCount = 0.0;

    const onClickHandler = (e) => {
        if (typeof onClick === 'function') onClick();
        else console.warn('No onClick handler provided for BatchSubmissionActivity: ' + batch.batchId);
    };
    
    return (
        <div className="partner-activity batch-submission results">
            <div className="batch-submission-total-count">
                <NumberDisplay value={percent} path={path} />%
                <label><NumberDisplay decimalPlaces={0} value={updateCount} /> Of <NumberDisplay value={batch.submittedCount} /> Updates</label>
            </div>
            <h4>
                <FontAwesomeIcon icon={faSquarePollVertical} />
                <Link to={path}>Batch Card Updates {batch.batchId}</Link>
                <span className="batch-date" onClick={onClickHandler}><DateTime value={(batch.modified || batch.created).toString()} time={true} /></span>
                <label>Submerchant: <Link to={merchantPath}>{batch.subMerchant?.name}</Link></label>
            </h4>
            <div className="card-brands">
                <span className="card visa">
                    <span className="content">
                        <VisaCard height={24} visaColor="#AAA9FF" swishColor="#AAF" />
                        <span className="value">{ batch.visaCount }</span>
                    </span>
                </span>
                <span className="card mastercard">
                    <span className="content">
                        <MasterCard height={24} leftCircleColor="#D9555C" />
                        <span className="value">{ batch.masterCardCount }</span>
                    </span>
                </span>
                <span className="card discover">
                    <span className="content">
                        <DiscoverCard height={24} />
                        <span className="value">{ batch.discoverCount }</span>
                    </span>
                </span>
                <span className="card amex">
                    <span className="content">
                        <AmericanExpressCard height={24} adjusted={true} />
                        <span className="value">{ batch.amexCount }</span>
                    </span>
                </span>
            </div>
        </div>
    );
};

export default BatchResultsActivity;