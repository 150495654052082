import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PartnersScreen from "./routes/partners/PartnersScreen";
import ReportingScreen from "./routes/reporting/ReportingScreen";
import SettingsScreen from "./routes/settings/SettingsScreen";
import PartnerDetailsScreen from "./routes/partners/PartnerDetailsScreen";
import PartnerBillingDetailsScreen from "./routes/partners/billing/PartnerBillingDetailsScreen";
import PartnerAuthenticationDetailsScreen from "./routes/partners/PartnerAuthenticationDetailsScreen";
import PartnerAccountDetailsScreen from "./routes/partners/PartnerAccountDetailsScreen";
import PartnerEnrollmentsScreen from "./routes/partners/PartnerEnrollmentsScreen";
import PartnerWebhooksScreen from "./routes/partners/PartnerWebhooksScreen";
import MessageDetailsScreen from "./routes/settings/MessageDetailsScreen";
import CompanyInfoScreen from "./routes/settings/CompanyInfoScreen";
import MessagingCredsScreen from "./routes/settings/MessagingCredsScreen";
import SystemConfigScreen from "./routes/settings/SystemConfigScreen";
import AutomationScreen from "./routes/settings/AutomationScreen";
import MerchantEditScreen from "./routes/partners/MerchantEditScreen";
import BatchDetailsScreen from "./routes/partners/BatchDetailsScreen";
import TrendsScreen from "./routes/reporting/TrendsScreen";
import CardBrandsScreen from "./routes/reporting/CardBrandsScreen";
import BatchReportsScreen from "./routes/reporting/BatchReportsScreen";
import CustomReportsScreen from "./routes/reporting/CustomReportsScreen";
import MerchantBatchesScreen from "./routes/partners/MerchantBatchesScreen";
import CustomerSupportScreen from "./routes/support/CustomerSupportScreen";
import MessagesScreen from "./routes/settings/MessagesScreen";
import TicketDetailsScreen from "./routes/support/TicketDetailsScreen";
import PartnerWebhookLogsScreen from "./routes/partners/PartnerWebhookLogsScreen";
import MerchantEnrollmentDetailsScreen from "./routes/partners/MerchantEnrollmentDetailsScreen";
import PartnerStatementsScreen from "./routes/partners/billing/PartnerStatementsScreen";
import AutomationJobLogsScreen from "./routes/settings/AutomationJobLogsScreen";
import UsersScreen from "./routes/people/UsersScreen";
import UserDetailsScreen from "./routes/people/UserDetailsScreen";
import UserAccessScreen from "./routes/people/UserAccessScreen";
import UserActivityHistoryScreen from "./routes/people/UserActivityHistoryScreen";
import UserSessionHistoryScreen from "./routes/people/UserSessionHistoryScreen";
import PasswordResetScreen from "./routes/public/PasswordResetScreen";
import ComplianceScreen from "./routes/settings/ComplianceScreen";
import PartnerBatchReportingScreen from "./routes/partners/PartnerBatchReportingScreen";
import PartnerCardBrandReportingScreen from "./routes/partners/PartnerCardBrandReportingScreen";
import AutomationJobsScreen from "./routes/settings/AutomationJobsScreen";
import Error404Screen from "./routes/errors/Error404Screen";
import PartnerStatementDetailsScreen from "./routes/partners/billing/PartnerStatementDetailsScreen";
import PlansScreen from "./routes/plans/PlansScreen";
import PlanEditScreen from "./routes/plans/PlanEditScreen";
import AmericanExpressScreen from "./routes/partners/batches/AmericanExpressScreen";
import ConfigModel from "@paylani/paylani-react-packages/dist/common/models/ConfigModel";

ConfigModel.appName = "CardSync Portal";
ConfigModel.companyName = "CardSync";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />

            <Route path="/accountreset/:resetId" element={<PasswordResetScreen />} />

            <Route path="/partners" element={<PartnersScreen />} />
            <Route path="/partners/:partnerId" element={<PartnerDetailsScreen />} />
            <Route path="/partners/:partnerId/batchactivity" element={<PartnerBatchReportingScreen />} />
            <Route path="/partners/:partnerId/cardbrandactivity" element={<PartnerCardBrandReportingScreen />} />
            
            <Route path="/partners/:partnerId/account" element={<PartnerAccountDetailsScreen />} />
            <Route path="/partners/:partnerId/enrollments" element={<PartnerEnrollmentsScreen />} />
            <Route path="/partners/:partnerId/statements" element={<PartnerStatementsScreen />} />
            <Route path="/partners/:partnerId/statements/:statementId" element={<PartnerStatementDetailsScreen />} />
            <Route path="/partners/:partnerId/billing" element={<PartnerBillingDetailsScreen />} />
            <Route path="/partners/:partnerId/authentication" element={<PartnerAuthenticationDetailsScreen />} />
            <Route path="/partners/:partnerId/merchants/:merchantId" element={<MerchantBatchesScreen />} />
            <Route path="/partners/:partnerId/merchants/:merchantId/details" element={<MerchantEnrollmentDetailsScreen />} />
            <Route path="/partners/:partnerId/merchants/:merchantId/edit" element={<MerchantEditScreen />} />
            <Route path="/partners/:partnerId/merchants/:merchantId/batch/:batchId" element={<BatchDetailsScreen />} />
            <Route path="/partners/:partnerId/webhooks" element={<PartnerWebhooksScreen />} />
            <Route path="/partners/:partnerId/webhooks/logs" element={<PartnerWebhookLogsScreen />} />

            <Route path="/partners/:partnerId/american-express" element={<AmericanExpressScreen />} />
            
            <Route path="/support" element={<CustomerSupportScreen />} />
            <Route path="/support/:threadId" element={<TicketDetailsScreen />} />
            <Route path="/support/:threadId/:supportTicketId" element={<TicketDetailsScreen />} />

            <Route path="/reporting" element={<ReportingScreen />} />
            <Route path="/reporting/batches" element={<BatchReportsScreen />} />
            <Route path="/reporting/card-brands" element={<CardBrandsScreen />} />
            <Route path="/reporting/trends" element={<TrendsScreen />} />
            <Route path="/reporting/custom-reports" element={<CustomReportsScreen />} />

            <Route path="/settings" element={<SettingsScreen />} />
            <Route path="/settings/company" element={<CompanyInfoScreen />} />
            <Route path="/settings/messaging" element={<MessagingCredsScreen />} />
            <Route path="/settings/system-messages" element={<MessagesScreen />} />
            <Route path="/settings/system-messages/:messageId" element={<MessageDetailsScreen />} />
            <Route path="/settings/system" element={<SystemConfigScreen />} />
            <Route path="/settings/automation" element={<AutomationScreen />} />
            <Route path="/settings/automation/jobs" element={<AutomationJobsScreen />} />
            <Route path="/settings/automation/joblogs" element={<AutomationJobLogsScreen />} />
            <Route path="/settings/pci" element={<ComplianceScreen />} />
            
            <Route path="/people" element={<UsersScreen />} />
            <Route path="/people/:userId" element={<UserDetailsScreen />} />
            <Route path="/people/:userId/access" element={<UserAccessScreen />} />
            <Route path="/people/:userId/activity" element={<UserActivityHistoryScreen />} />
            <Route path="/people/:userId/sessions" element={<UserSessionHistoryScreen />} />

            <Route path="/plans" element={<PlansScreen />} />
            <Route path="/plans/:planId" element={<PlanEditScreen />} />

            <Route path="*" element={<Error404Screen />} />
        </Routes>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

